import { createContext } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export type AccountSubmenuContext = {
    setIsAccountMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AccSubmenuContext = createContext<AccountSubmenuContext | undefined>(undefined);
