import { NavContext } from 'components/Intaserve/intaserveSidebar/contexts/navContext/navigationContext';
import { useContext } from 'react';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NavigationContext } from 'components/Intaserve/intaserveSidebar/contexts/navContext/navigationContext';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useNavContext = (): NavigationContext => {
    const context = useContext(NavContext);
    if (!context) {
        throw new Error('useNavigation must be used within a NavigationProvider');
    }

    /***** HOOK RESULTS *****/
    return context;
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
