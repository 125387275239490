import { styled, Tooltip, tooltipClasses } from '@mui/material';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { TooltipProps } from '@mui/material';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Wrap your component in the Submenu to use this component
 */
export const Submenu = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#ffffff',
        color: 'rgba(0, 0, 0, 0.87)',
        width: 220,
        borderRadius: '15px',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
        padding: '0px'
    }
}));
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
