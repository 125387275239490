import { useMatches } from '@tanstack/react-router';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import 'components/Intaserve/intaserveSidebar/components/navigation/sidebarFolder/_sidebarFolder.scss';
import { NavFolderContext } from 'components/Intaserve/intaserveSidebar/contexts/folderContext/navigationFolderContext';
import { useNavContext } from 'components/Intaserve/intaserveSidebar/contexts/navContext/useNavContext';
import { useConditionalFontSize } from 'components/Intaserve/intaserveSidebar/hooks/useConditionalSize';
import { Revealer } from 'components/Revealer';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import React from 'react';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import classNames from 'classnames';
import type { IconifyIconNamespace } from 'components/Icons/types';
import type { FolderLinkProps } from 'components/Intaserve/intaserveSidebar/components/navigation/types';
import type { ReactNode } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SidebarFolder = React.FC<SidebarFolderProps>;
type SidebarFolderProps = {
    /**
     *  Text to show as the Folders label
     */
    title: string;
    
    /**
     *  The icon that sits to the left of the folder label.
     */
    Icon: IconifyIconNamespace.Component & { Fill: IconifyIconNamespace.Component };
    
    /**
     *  The links you want to render inside the folder
     */
    children: Array<ReactNode>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SidebarFolder: SidebarFolder = ({ title, Icon, children }) => {
    /***** STATE *****/
    const [links, setLinks] = React.useState(new Set<FolderLinkProps>());

    /***** HOOKS *****/
    const { folderFontSize, folderIconSize } = useConditionalFontSize();
    const { openFolder, setOpenFolder } = useNavContext();

    // Check if the current route matches any link paths inside this folder
    const isActiveRouteInsideFolder = useMatches({
        select: (matches) => {
            const lastMatch = matches.at(-1);
            if (lastMatch) {
                return Array.from(links).some((pathMatch) => pathMatch?.path === lastMatch.fullPath);
            }
            return false;
        },
    });

    // If the active route is inside this folder
    // OR the title of the folder is the current openFolder
    // THEN the folder is open
    const isFolderOpen = isActiveRouteInsideFolder || openFolder === title;

    /***** FUNCTIONS *****/
    function handleFolderClick() {
        if (isActiveRouteInsideFolder) return;

        setOpenFolder(isFolderOpen ? '' : title);
    }

    /***** DYNAMIC CLASSNAMES *****/
    // IF the folder is open THEN show the fill icon
    const iconClass = classNames({
        'sidebarFolder__icon--hidden': isFolderOpen,
        'sidebarFolder__icon': !isFolderOpen,
    });
    const iconFillClass = classNames({ 'sidebarFolder__iconFill': isFolderOpen, 'sidebarFolder__iconFill--hidden': !isFolderOpen });

    // Other classnames
    const folderContainerClasses = classNames('sidebarFolder__folderContainer', {
        'sidebarFolder__folderContainer--isOpen': isActiveRouteInsideFolder,
    });
    const folderClassNames = classNames('sidebarFolder', {
        'sidebarFolder--isOpen': isFolderOpen,
    });

    /***** RENDER *****/
    return (
        <NavFolderContext.Provider value={{ links, setLinks }}>
            <div className={folderContainerClasses}>
                <button onClick={() => handleFolderClick()} className={folderClassNames}>
                    <Padding paddingOnly x={3} top={3} bottom={3}>
                        <Flex align="center" justify="between" fullHeight>
                            <Flex gap={3} align="center">
                                {/* render both icons to stop the layout shift when switching between them */}
                                <Icon className={iconClass} size={folderIconSize} />
                                <Icon.Fill className={iconFillClass} size={folderIconSize} />
                                <Text size--custom={folderFontSize} align--left bold className="sidebarFolder__textColorTransition">
                                    {title}
                                </Text>
                            </Flex>
                            <PhosphorIcons.Chevron bold size={folderIconSize} state={isFolderOpen ? 'down' : 'right'} />
                        </Flex>
                    </Padding>
                </button>
                <Revealer isOpen={isFolderOpen}>
                    <div className="sidebarFolder__folderLinksContainer">{children}</div>
                </Revealer>
            </div>
        </NavFolderContext.Provider>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
