import { ClickAwayListener } from '@mui/material';
import classNames from 'classnames';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { AccountSubmenu } from 'components/Intaserve/intaserveSidebar/components/accountSubmenu';
import { AccSubmenuContext } from 'components/Intaserve/intaserveSidebar/components/accountSubmenu/context/accountSubmenuContext';
import { useConditionalFontSize } from 'components/Intaserve/intaserveSidebar/hooks/useConditionalSize';
import { Submenu } from 'components/Intaserve/submenu';
import Avatar from 'components/Placeholders/Avatar';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useState } from 'react';
import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';
import { NXQuery } from 'utilities/query';
import './_accountNavigationButton.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const AccountNavigationButton = () => {
    /***** STATE *****/
    const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);

    /***** HOOKS *****/
    const { folderFontSize, accountNavIconSize } = useConditionalFontSize();
    const isMediaMobile = ViewportManager.useAppMediaViewport(['xs', 'sm']);
    const isMediaTablet = ViewportManager.useAppMediaViewport(['md']);

    /***** QUERIES *****/
    const { data: { firstname, lastname } } = NXQuery.auth.userData.useSelectSuspenseQuery(void 0, ({ data }) => data.attributes);

    // IF accMenu is open add additional styles
    const accountNavFolderClassNames = classNames('accountNavigation__folder', {
        'accountNavigation__folder--isOpen': isAccountMenuOpen,
    });

    /***** RENDER *****/
    return (
        <AccSubmenuContext.Provider value={{ setIsAccountMenuOpen }}>
            <ClickAwayListener onClickAway={() => setIsAccountMenuOpen(false)}>
                <div>
                    <Submenu
                        title={<AccountSubmenu />}
                        placement={isMediaMobile ? 'top' : 'right-end'}
                        open={isAccountMenuOpen}
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -10],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <div className="accountNavigation">
                            <button onClick={() => setIsAccountMenuOpen(!isAccountMenuOpen)} className={accountNavFolderClassNames}>
                                <Padding paddingOnly x={3} top={3} bottom={3}>
                                    <Flex align="center" justify="between" gap={3} fullHeight>
                                        <Avatar
                                            size={isMediaTablet ? 40 : 50}
                                            fontSize={isMediaTablet ? 15 : 20}
                                            fullname={`${firstname} ${lastname}`}
                                        />
                                        <Text
                                            color="S_color_text_inverse_base"
                                            size--custom={folderFontSize}
                                            align--left
                                            bold
                                            className="intaserveSidebar__textColorTransition"
                                            lead--none
                                        >
                                            {firstname} {lastname}
                                        </Text>

                                        <Flex direction="column" gap={0}>
                                            <PhosphorIcons.Caret.Up.Bold size={accountNavIconSize} />
                                            <PhosphorIcons.Caret.Down.Bold size={accountNavIconSize} />
                                        </Flex>
                                    </Flex>
                                </Padding>
                            </button>
                        </div>
                    </Submenu>
                </div>
            </ClickAwayListener>
        </AccSubmenuContext.Provider>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
