import NXBox from 'components/NXBox';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import 'containers/orderingForm/pages/transferPage/transferAvailabilityConfirmation/transferSuccessConfirmation/_transferSuccessConfirmation.scss';
import { TransferButton } from 'containers/orderingForm/pages/transferPage/transferButton';
import type { DomainData } from 'containers/orderingForm/types';
import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';
import { truncateText } from 'utilities/methods/commonActions';
import { NXQuery } from 'utilities/query';

type TransferSuccessConfirmation = React.FC<TransferSuccessConfirmationProps>;
type TransferSuccessConfirmationProps = {
    transferDomainData: DomainData;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const TransferSuccessConfirmation: TransferSuccessConfirmation = ({ transferDomainData }) => {
    /***** QUERIES *****/
    const { data: transfer_domain_availibility_data } = NXQuery.domain.availability.useQuery(transferDomainData);
    const isMobile = ViewportManager.useAppMediaViewport(['xs', 'sm']);

    const truncateLength = isMobile ? 30 : 40;

    /***** RENDER *****/
    return (
        <NXBox className="transferSuccessConfirmation__container">
            <Padding xy={1}>
                <Text size--m align--center>
                    Great news! This domain is available for transfer
                </Text>
                <Flex align="center" className="transferSuccessConfirmation__information">
                    <Flex direction="column" gap={0} fullWidth>
                        <Text size--xm bold>
                            {truncateText(transfer_domain_availibility_data?.[0].domain.toLowerCase(), truncateLength, '...')}
                            {transfer_domain_availibility_data?.[0].tld}
                        </Text>
                        <Text>{`$${transfer_domain_availibility_data?.[0].price[0].price}/yr`}</Text>
                    </Flex>
                    <TransferButton domainAvalibility={transfer_domain_availibility_data} />
                </Flex>
            </Padding>
        </NXBox>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
