import { Store, useStore } from '@tanstack/react-store';
import { APP_DISPLAY_STATES } from 'App/actionTypes';
import { useCallback, useEffect } from 'react';
import store from 'store/store';
import { useModalViewportContext } from 'utilities/classes/viewportManager/context';

type Match = TViewport | TViewport[];
type DefaultReturn<TMatch extends Match | undefined> = TMatch extends undefined ? TViewport : boolean;
type Select<TMatch extends Match | undefined, TSelected> = (result: DefaultReturn<TMatch>) => TSelected;
type ViewportStore = {
    containerViewport: TViewport;
    overlayContainerViewport: TViewport;
    rootViewport: TViewport;
};

/**
 * Manager for updating the application viewport throughout the application. This class contains static methods
 * for registering the viewport container and updating the viewport as well as accessing the viewport state.
 */
export class ViewportManager {
    // public:
    public static container: Element | null = null;
    public static overlayContainer: Element | null = null;
    public static store = new Store<ViewportStore>({
        containerViewport: 'xs',
        overlayContainerViewport: 'xs',
        rootViewport: 'xs'
    });

    public static xs = 480;
    public static sm = 768;
    public static md = 1024;
    public static lg = 1440;

    public static useRegisterElementAsContainer(containerType: 'container' | 'overlayContainer') {
        /***** EFFECTS *****/
        useEffect(() => {
            // make sure to update the viewport in case we are opening a lightbox and therefore need to update the viewport
            ViewportManager.updateOverlayContainerViewport();
        }, []);

        /***** FUNCTIONS *****/
        const registerElementAsContainer = useCallback((element: HTMLDivElement | null) => {
            ViewportManager[containerType] = element;

            if (ViewportManager[containerType]) {
                ViewportManager.updateViewport();
            }
        }, []);

        /***** HOOK RESULTS *****/
        return registerElementAsContainer;
    }

    public static registerEventListeners() {
        addEventListener('resize', ViewportManager.updateViewport);

        return () => removeEventListener('resize', ViewportManager.updateViewport);
    }

    /**
     * AppViewport hook that can be used to get the media viewport as a Viewport in react. This should only be used when outside of the
     * ViewportManager container component, otherwise the `useAppViewport` hook should be used instead.
     */
    public static useAppMediaViewport = <TMatch extends Match | undefined, TSelected = DefaultReturn<TMatch>>(
        match: TMatch,
        selector?: Select<TMatch, TSelected>
    ) => ViewportManager.useAnyViewport((state) => state.rootViewport as TViewport, match, selector);

    /**
     * AppViewport hook that can be used to get the viewport as a Viewport in react. This should only be used when inside of the
     * ViewportManager container component, otherwise the `useAppMediaViewport` hook should be used instead.
     *
     * This is the logical successor to the standard `useAppViewport` hook.
     *
     * @note This hook should not be used inside a lightbox, instead use `useAppMediaViewport` as lightboxes do not depend on the size of the
     * viewport container.
     */
    public static useAppViewport = <TMatch extends Match | undefined = undefined, TSelected = DefaultReturn<TMatch>>(
        match?: TMatch,
        selector?: Select<TMatch, TSelected>
    ) => {
        const isInsideOverlayViewportContainer = useModalViewportContext();

        // prettier-ignore
        const selectViewport = (state: ViewportStore) => isInsideOverlayViewportContainer 
            ? state.overlayContainerViewport ?? 'xs'
            : state.containerViewport ?? 'xs';

        return ViewportManager.useAnyViewport(selectViewport, match, selector);
    };

    // private:
    private static updateViewport() {
        ViewportManager.updateContainerViewport();
        ViewportManager.updateOverlayContainerViewport();
        ViewportManager.updateRootViewport();
    }

    private static useAnyViewport<TMatch extends Match | undefined, TSelected = DefaultReturn<TMatch>>(
        stateSelector: (state: ViewportStore) => TViewport,
        match: TMatch,
        selector?: Select<TMatch, TSelected>
    ): TSelected {
        const internalSelect = selector ?? ((match: boolean | TViewport): any => match);

        /***** HOOKS *****/
        return useStore(ViewportManager.store, (state: any) => {
            const viewport = stateSelector(state);

            if (Array.isArray(match)) {
                const isMatched: any = match.includes(viewport ?? 'xs');
                return internalSelect(isMatched);
            }

            if (match) {
                const isMatched: any = viewport === match;
                return internalSelect(isMatched);
            }

            return internalSelect(viewport as any);
        });
    }

    private static getContainerViewport({ clientWidth }: Element) {
        switch (true) {
            case clientWidth <= ViewportManager.xs:
                return 'xs';
            case clientWidth > ViewportManager.xs && clientWidth <= ViewportManager.sm:
                return 'sm';
            case clientWidth > ViewportManager.sm && clientWidth <= ViewportManager.md:
                return 'md';
            case clientWidth > ViewportManager.md && clientWidth <= ViewportManager.lg:
                return 'lg';
            case clientWidth > ViewportManager.lg:
                return 'xl';
        }
    }

    private static updateContainerViewport() {
        if (!ViewportManager.container) {
            console.warn('[ViewportManager] Viewport could not be calculated because the container size is not available');
            return;
        }

        const { app_viewport } = store.getState().app;
        const containerViewport = ViewportManager.getContainerViewport(ViewportManager.container) ?? 'xs';

        if (containerViewport !== app_viewport) {
            ViewportManager.store.setState((state) => {
                return { ...state, containerViewport };
            });
        }

        store.dispatch({
            type: APP_DISPLAY_STATES,
            app_viewport: containerViewport
        });
    }

    private static updateOverlayContainerViewport() {
        if (!ViewportManager.overlayContainer) return;

        const overlayContainerViewport = ViewportManager.getContainerViewport(ViewportManager.overlayContainer) ?? 'xs';
        ViewportManager.store.setState((state) => ({ ...state, overlayContainerViewport }));
    }

    private static updateRootViewport() {
        const { app_media_viewport } = store.getState().app;
        const rootViewport = ViewportManager.getContainerViewport(document.getElementById('root')!) ?? 'xs';

        if (rootViewport !== app_media_viewport) {
            ViewportManager.store.setState((state) => ({ ...state, rootViewport }));
        }
    }
}
