import { NavFolderContext } from 'components/Intaserve/intaserveSidebar/contexts/folderContext/navigationFolderContext';
import { useContext } from 'react';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NavigationFolderContext } from 'components/Intaserve/intaserveSidebar/contexts/folderContext/navigationFolderContext';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useNavFolderContext = (): NavigationFolderContext => {
    const context = useContext(NavFolderContext);
    if (!context) {
        throw new Error('useNavigation must be used within a NavigationProvider');
    }

    /***** HOOK RESULTS *****/
    return context;
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
