import classNames from 'classnames';
import Header from 'components/Header';
import { IntaserveSideNavbar } from 'components/Intaserve/intaserveSidebar';
import { MobileHeader } from 'components/Intaserve/mobileHeader';
import SideBarPage from 'components/SideBarPage';
import { useSuperUserItem } from 'components/StaffMenu/SuperUser/hooks/useSuperUserItem';
import { WrapWithComponent } from 'components/WrapWithComponent';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { OrderingForm } from 'containers/orderingForm';
import { useState } from 'react';
import { ViewportContainer } from 'utilities/classes/viewportManager/viewportContainer';
import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';
import './_intaserveUser.scss';
import { RegistrationOrderingFormContext } from './contexts/registrationOrderingFormContext/context';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type IntaserveUserRouteTemplateComponent = React.FC<{
    outlet: React.ReactNode;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Renders User related components when the route is authenticated.
 */
export const IntaserveUserRouteTemplate: IntaserveUserRouteTemplateComponent = ({ outlet }) => {
    /***** STATE *****/
    const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
    const [isDomainRegistrationFormOpen, setIsDomainRegistrationFormOpen] = useState(false);
    const [isRegistration, setIsRegistration] = useState(true);

    /***** HOOKS *****/
    const { activeBrand } = useBrandStore();
    const isSidebarVisible = useSuperUserItem('TOGGLE_INTASERVE_SIDEBAR', false);

    const isMediaMobile = ViewportManager.useAppMediaViewport(['xs', 'sm']);
    const isMediaTablet = ViewportManager.useAppMediaViewport('md');

    /***** RENDER HELPERS *****/
    const contextValue = {
        isDomainRegistrationFormOpen,
        setIsDomainRegistrationFormOpen,
        isRegistration,
        setIsRegistration,
    };
    const outerClasses = classNames('UserLayout', `UserLayout--${activeBrand}`);
    const showGrid = !isMediaMobile && isSidebarVisible;

    /***** RENDER *****/
    return (
        <div className={outerClasses}>
            {/* 
            
                Uncomment this and replace below code when we are ready to permanently enable the sidebar

                <SideBarPage.Wrapper>
                    <RegistrationOrderingFormContext.Provider value={contextValue}>
                        {isMediaMobile && <MobileHeader setIsMobileSidebarOpen={setIsMobileSidebarOpen} />}
                        <div data-show={showGrid} data-is-tablet={isMediaTablet} className="intaserveUser">
                            <IntaserveSideNavbar isMobileSidebarOpen={isMobileSidebarOpen} setIsMobileSidebarOpen={setIsMobileSidebarOpen} />
                            <ViewportContainer>{outlet}</ViewportContainer>
                        </div>
                        <OrderingForm />
                    </RegistrationOrderingFormContext.Provider>
                </SideBarPage.Wrapper>
            
            */}
            <WrapWithComponent wrap={!isSidebarVisible} component={ViewportContainer}>
                {!isSidebarVisible && <Header />}
                <WrapWithComponent wrap={isSidebarVisible} component={SideBarPage.Wrapper}>
                    <RegistrationOrderingFormContext.Provider value={contextValue}>
                        {isSidebarVisible && isMediaMobile && <MobileHeader setIsMobileSidebarOpen={setIsMobileSidebarOpen} />}
                        <div data-show={showGrid} data-is-tablet={isMediaTablet} className="intaserveUser">
                            {isSidebarVisible && (
                                <IntaserveSideNavbar isMobileSidebarOpen={isMobileSidebarOpen} setIsMobileSidebarOpen={setIsMobileSidebarOpen} />
                            )}
                            <ViewportContainer>{outlet}</ViewportContainer>
                        </div>
                        <OrderingForm />
                    </RegistrationOrderingFormContext.Provider>
                </WrapWithComponent>
            </WrapWithComponent>
        </div>
    );
};
