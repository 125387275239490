/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import { serviceNav, service_status } from 'config/config';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { SolidButton } from 'components/Buttons/SolidButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import RequestLoader from 'components/Loaders/Request';
import { Revealer } from 'components/Revealer';
import SolidTag from 'components/Tags/SolidTag';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import Accounts from 'containers/dashboard/modules/accounts';
import Alerts from 'containers/dashboard/modules/alerts';

/*********************************** ***********************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { withBrandStore } from 'config/hooks/useBrandStore';
import { ConditionalServiceNavKeys } from 'utilities/hooks/useConditionalServiceNavKeys';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import IntaserveLogoSmall from 'assets/images/intaserve-header-logo-small.svg';
import IntaserveLogo from 'assets/images/intaserve-header-logo.svg';
import VentraLogoSmall from 'assets/images/ventra-header-logo-small.svg';
import VentraLogo from 'assets/images/ventra-header-logo.svg';
import { FastLink } from 'components/FastLink';

import { logoutApp } from 'components/Header/action';
import { withHeaderRouter } from 'components/Header/withHeaderRouter';
import { RenderForBrands } from 'config/brandRenderer/component';
import { renderForBrands } from 'config/brandRenderer/helper';
import { HeaderSupportPIN } from 'containers/account/modules/supportPIN/headerSupportPIN';
import { getPurchaseLink, getPurchaseLinkIcon } from 'containers/services/consts';
import { vipRewardsReadableName } from 'containers/vipRewards/consts';
import { isRouteActive } from 'router/utils/isRouteActive';
import { showNavigation } from 'utilities/consts';
import { NXQuery } from 'utilities/query';
import './_Header.scss';

const supportLinks = [
    { label: 'Support Centre', link: '/support/support-centre', icon: 'support' },
    { label: 'eTickets', link: '/support/tickets', icon: 'eticket' },
    { label: 'Migration Request', link: '/support/migration-request', icon: 'icon-migrations' },
    { label: 'Feedback', link: '/support/feedback', icon: 'customer-service' },
    { label: 'Service Status', external: true, link: service_status, icon: 'service-status' }
];

/**
 * @type {Parameters<typeof getPurchaseLink>[0][]}
 */
const serviceNames = ['Domain Names', 'Web Hosting', 'Email Hosting', 'Google Workspace', 'Microsoft 365', 'VPS', 'SSL Certificates'];

const purchaseLinkObjects = serviceNames.map((name) => ({
    name,
    link: getPurchaseLink(name, { utmMedium: 'purchase-dropdown' }),
    icon: getPurchaseLinkIcon(name)
}));

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class Header extends Component {
    constructor(props) {
        super(props);

        this.onClickLogout = this.onClickLogout.bind(this);
        this.openSidenav = this.openSidenav.bind(this);
        this.closeSidenav = this.closeSidenav.bind(this);
        this.onClickAway = this.onClickAway.bind(this);
        this.toggleServiceDropdown = this.toggleServiceDropdown.bind(this);
        this.togglePurchaseDropdown = this.togglePurchaseDropdown.bind(this);
        this.toggleSupportDropdown = this.toggleSupportDropdown.bind(this);

        this.state = {
            showServiceDropdown: null,
            currentShowingPurchaseDropdown: null,
            hideSupportDropdown: false,
            currentShowingSupportDropdown: null,
            showSidenav: false
        };

        this.sidenavRef = createRef();
        this.hamburgerRef = createRef();
    }

    onClickLogout() {
        const { router } = this.props;
        logoutApp(router);
    }

    openSidenav() {
        this.setState({
            showSidenav: true
        });
    }

    closeSidenav() {
        this.setState({
            showSidenav: false,
            showServiceDropdown: null,
            currentShowingPurchaseDropdown: null,
            currentShowingSupportDropdown: null
        });
    }

    onClickAway(e) {
        const { sidenavRef, hamburgerRef, closeSidenav } = this;

        // Close the sidebar if the click is not within the sidebar or the sidebar toggle button (hamburger)
        // Closing of the sidebar when clicking the links inside the sidebar is handled by closing the sidebar on url changes
        if (sidenavRef?.current && !sidenavRef.current.contains(e.target) && hamburgerRef?.current && !hamburgerRef.current.contains(e.target))
            closeSidenav();
    }

    toggleServiceDropdown() {
        const { showServiceDropdown } = this.state;

        this.setState({
            showServiceDropdown: showServiceDropdown === 'mobile' ? null : 'mobile',
            currentShowingPurchaseDropdown: null,
            currentShowingSupportDropdown: null
        });
    }

    togglePurchaseDropdown(which) {
        const { currentShowingPurchaseDropdown } = this.state;

        this.setState({
            currentShowingPurchaseDropdown: currentShowingPurchaseDropdown === which ? null : which,
            currentShowingSupportDropdown: null,
            showServiceDropdown: null
        });
    }

    toggleSupportDropdown(which) {
        const { currentShowingSupportDropdown } = this.state;

        this.setState({
            currentShowingSupportDropdown: currentShowingSupportDropdown === which ? null : which,
            currentShowingPurchaseDropdown: null,
            showServiceDropdown: null
        });
    }

    matomoPageView() {
        if (window && window._paq) {
            try {
                window._paq.push(['setCustomUrl', window.location.href]);
                window._paq.push(['setDocumentTitle', window.document.title]);
                window._paq.push(['trackPageView']);
                window._paq.push(['enableLinkTracking']);
            } catch (e) {
                console.warn('Failed to track', e);
            }
        }
    }

    componentDidMount() {
        const { onClickAway } = this;

        document.addEventListener('click', onClickAway);
    }

    componentDidUpdate(prevProps) {
        const { togglePurchaseDropdown, closeSidenav, toggleSupportDropdown } = this;
        const { app_viewport, location } = this.props;
        const { currentShowingPurchaseDropdown, currentShowingSupportDropdown } = this.state;

        if (['md', 'sm', 'xs'].includes(prevProps.app_viewport) && !['md', 'sm', 'xs'].includes(app_viewport)) {
            closeSidenav();
        }

        if (['md', 'sm', 'xs'].includes(app_viewport)) {
            if (currentShowingSupportDropdown === 'desktop') {
                toggleSupportDropdown('desktop');
            }

            if (currentShowingPurchaseDropdown === 'desktop') {
                togglePurchaseDropdown('desktop');
            }
        }

        if (location !== prevProps.location) {
            const { matomoPageView } = this;

            //Don't update on support centre category (search param) changes due to lag
            if (!location.pathname.includes('/support/support-centre') || location.pathname !== prevProps.location.pathname) {
                matomoPageView();
            }

            // Close the mobile sidenav if the route changes
            closeSidenav();
        }
    }

    componentWillUnmount() {
        const { onClickAway } = this;

        document.removeEventListener('click', onClickAway);
    }

    render() {
        const { location, app_viewport, app_user_data, activeBrand, application } = this.props;
        const { showServiceDropdown, currentShowingPurchaseDropdown, currentShowingSupportDropdown, showSidenav, hideSupportDropdown } = this.state;
        const {
            toggleServiceDropdown,
            togglePurchaseDropdown,
            toggleSupportDropdown,
            openSidenav,
            closeSidenav,
            onClickLogout,
            sidenavRef,
            hamburgerRef
        } = this;

        const checkPathname = (url) => location.pathname.indexOf(url) > -1;

        const getInitials = () => {
            let initial = '';
            if (app_user_data?.data.attributes?.firstname) {
                initial += app_user_data?.data.attributes.firstname.substring(0, 1);
            }
            if (app_user_data?.lastname) {
                initial += app_user_data?.data.attributes.lastname.substring(0, 1);
            }
            return initial;
        };

        const classes = {
            logo: classNames({
                'HeaderLink--logo': true
            }),
            dashboard: classNames({
                HeaderLink: true,
                selected: checkPathname('/dashboard')
            }),
            myServices: classNames({
                HeaderLink: true,
                selected: checkPathname('/my-services')
            }),
            support: classNames({
                HeaderLink: true,
                purchase: true,
                selected: checkPathname('/support'),
                hide: !!hideSupportDropdown
            }),
            purchase: classNames({
                HeaderLink: true,
                purchase: true
            }),
            vipRewards: classNames({
                'HeaderLink': true,
                'selected': checkPathname('/vip-rewards'),
                'HeaderLink--vipRewards': true
            }),
            billing: classNames({
                HeaderLink: true,
                selected: checkPathname('/billing')
            })
        };

        const renderPurchaseOptions = () => {
            return React.Children.toArray(
                purchaseLinkObjects.map(({ name, link }) => (
                    <div className="purchase--list">
                        <a href={link} target="_blank" rel="noreferrer">
                            {name}
                        </a>
                    </div>
                ))
            );
        };

        const renderSupportOptions = () => {
            const onClick = () => {
                this.setState({
                    hideSupportDropdown: true
                });

                setTimeout(() => {
                    this.setState({
                        hideSupportDropdown: false
                    });
                });
            };

            return React.Children.toArray(
                supportLinks.map(({ external, link, label }) => (
                    <div className="purchase--list">
                        {external ? (
                            <a href={link} target="_blank" rel="noreferrer">
                                {label}
                            </a>
                        ) : (
                            <FastLink to={link} onClick={onClick}>
                                {label}
                            </FastLink>
                        )}
                    </div>
                ))
            );
        };

        const renderMobileNavOption = (type) => {
            switch (type) {
                case 'purchase':
                    return purchaseLinkObjects.map(({ name, link, icon }) => {
                        return (
                            <a key={link} className="sideNavigation__option sub" target="_blank" rel="noreferrer" href={link}>
                                <i className={`icon icon-${icon}`} />
                                {name}
                            </a>
                        );
                    });

                case 'support':
                    if (renderForBrands(['intaserve'])) {
                        return (
                            <FastLink className="sideNavigation__option sub" rel="noreferrer" to="/contact-support">
                                <i className="icon icon-customer-service" />
                                Contact Support
                            </FastLink>
                        );
                    }

                    return React.Children.toArray(
                        supportLinks.map((item) => {
                            return item.external ? (
                                <a className="sideNavigation__option sub" href={item.link} target="_blank" rel="noreferrer">
                                    <i className={`icon icon-${item.icon}`} />
                                    {item.label}
                                </a>
                            ) : (
                                <FastLink key={item.link} className="sideNavigation__option sub" rel="noreferrer" to={item.link}>
                                    <i className={`icon icon-${item.icon}`} />
                                    {item.label}
                                </FastLink>
                            );
                        })
                    );

                default: {
                    return React.Children.toArray(
                        <ConditionalServiceNavKeys>
                            {(serviceNavKeys) =>
                                serviceNavKeys.map((item) => {
                                    return (
                                        <FastLink
                                            className={`sideNavigation__option sub ${checkPathname(serviceNav[item].link) ? 'selected' : ''}`}
                                            key={serviceNav[item].link}
                                            to={`${serviceNav[item].link}`}
                                        >
                                            <>
                                                {typeof serviceNav[item].icon === 'string' ? (
                                                    <i className={`icon icon-${serviceNav[item].icon}`} />
                                                ) : (
                                                    serviceNav[item].icon()
                                                )}
                                                {renderForBrands(['intaserve']) && item === 'cPanel Hosting' ? 'Hosting' : item}
                                            </>
                                        </FastLink>
                                    );
                                })
                            }
                        </ConditionalServiceNavKeys>
                    );
                }
            }
        };

        /*  RENDER MOBILE NAVIGATION
         **********************************************************************************************************/
        const renderMobileSideNav = () => {
            return (
                <aside className={`sideNavigation${showSidenav ? ' active' : ''}`}>
                    <div className="sideNavigation__background"></div>
                    <div ref={sidenavRef} className="sideNavigation__menu">
                        <div className="sideNavigation__account">
                            <button
                                className="sideNavigation__back"
                                onClick={(e) => {
                                    e.preventDefault();
                                    closeSidenav();
                                }}
                            >
                                <i className="icon icon-x" />
                            </button>
                            <div className="sideNavigation__profile">
                                <div className="sideNavigation__profile--img">{getInitials()}</div>
                                <div className="sideNavigation__details">
                                    <div className="sideNavigation__title">
                                        {app_user_data ? (
                                            `${app_user_data.data.attributes.firstname} ${app_user_data.data.attributes.lastname}`
                                        ) : (
                                            <RequestLoader />
                                        )}
                                    </div>
                                    <div className="sideNavigation__subtitle">
                                        {app_user_data ? <FastLink to="/account/general#overview">View Profile</FastLink> : <RequestLoader />}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sideNavigation__options">
                            <FastLink to="/dashboard">
                                <div className={`sideNavigation__option ${checkPathname('/dashboard') ? 'selected' : ''}`}>
                                    <i className="icon icon-apps" />
                                    Dashboard
                                </div>
                            </FastLink>

                            <button
                                key="serviceMenu"
                                className={`sideNavigation__option ${checkPathname('/my-services') ? 'selected' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    toggleServiceDropdown();
                                }}
                            >
                                <i className="icon icon-cloud-hardware" />
                                <Text align--left>My Services</Text>
                                <PhosphorIcons.Chevron state={showServiceDropdown === 'mobile' ? 'up' : 'down'} />
                            </button>

                            <Revealer isOpen={showServiceDropdown === 'mobile'}>{renderMobileNavOption()}</Revealer>

                            <button
                                className="sideNavigation__option"
                                onClick={() => {
                                    toggleSupportDropdown('mobile');
                                }}
                            >
                                <i className="icon icon-cart" />
                                <Text align--left>Support</Text>
                                <PhosphorIcons.Chevron state={currentShowingSupportDropdown === 'mobile' ? 'up' : 'down'} />
                            </button>

                            <Revealer isOpen={currentShowingSupportDropdown === 'mobile'}>{renderMobileNavOption('support')}</Revealer>

                            <FastLink to="/billing">
                                <div className={`sideNavigation__option ${checkPathname('/billing') ? 'selected' : ''}`}>
                                    <i className="icon icon-billing-icon" />
                                    Billing
                                </div>
                            </FastLink>
                            <RenderForBrands brands={['ventra']}>
                                <button
                                    className="sideNavigation__option"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        togglePurchaseDropdown('mobile');
                                    }}
                                >
                                    <i className="icon icon-cart" />
                                    <Text align--left>Purchase</Text>
                                    <PhosphorIcons.Chevron state={currentShowingPurchaseDropdown === 'mobile' ? 'up' : 'down'} />
                                </button>

                                <Revealer isOpen={currentShowingPurchaseDropdown === 'mobile'}>{renderMobileNavOption('purchase')}</Revealer>
                            </RenderForBrands>

                            <RenderForBrands brands={['ventra']}>
                                <FastLink to="/vip-rewards">
                                    <div
                                        className={`sideNavigation__option sideNavigation__option--vipRewards ${
                                            checkPathname('/vip-rewards') ? 'selected' : ''
                                        }`}
                                    >
                                        <i className="icon icon-billing-icon" />
                                        {vipRewardsReadableName}{' '}
                                        <SolidTag className="navVipRewardsTag" color="warn">
                                            NEW
                                        </SolidTag>
                                    </div>
                                </FastLink>
                            </RenderForBrands>
                        </div>
                        <div className="sideNavigation__login">
                            <SolidButton
                                type="onClick"
                                size="large"
                                color="warn"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onClickLogout();
                                }}
                            >
                                Logout
                            </SolidButton>
                        </div>
                    </div>
                </aside>
            );
        };

        function getSmallBrandLogo() {
            switch (activeBrand) {
                case 'intaserve':
                    return IntaserveLogoSmall;
                case 'ventra':
                default:
                    return VentraLogoSmall;
            }
        }

        function getFullBrandLogo() {
            switch (activeBrand) {
                case 'intaserve':
                    return IntaserveLogo;
                case 'ventra':
                default:
                    return VentraLogo;
            }
        }

        /*  RENDER COMPONENT
         **********************************************************************************************************/
        if (!app_user_data) return '';

        return (
            <header className="HeaderShared" id="HeaderShared">
                {/* @deprecated use `Revealer` instead */}
                <Fade left when={showSidenav} duration={500}>
                    {renderMobileSideNav()}
                </Fade>

                <section className="HeaderShared__section">
                    <div className="HeaderShared__left">
                        {['lg', 'xl'].includes(app_viewport) ? (
                            <>
                                <FastLink to="/dashboard" className={classes.logo}>
                                    {/* 
                                    // @ts-ignore */}
                                    <picture>
                                        <source
                                            srcSet={getSmallBrandLogo()}
                                            /*must line up with HeaderShared__logo @media */
                                            media="(min-width: 1025px) and (max-width: 1100px)"
                                        />
                                        <img alt={application} className="HeaderShared__logo" src={getFullBrandLogo()} />
                                    </picture>
                                </FastLink>
                                <div className={classes.dashboard}>
                                    <FastLink to="/dashboard">Dashboard</FastLink>
                                </div>
                                <div className={classes.myServices}>
                                    <FastLink to="/my-services/domains">My Services</FastLink>
                                </div>
                                {isRouteActive({ path: '/support' }) ? (
                                    <div className={classes.support}>
                                        <div className="HeaderLink__supportText">
                                            <FastLink to="/support" className="HeaderLink__link">
                                                Support
                                                <PhosphorIcons.Caret.Down.Bold secondary size={16} className="HeaderLink__chevron" />
                                            </FastLink>
                                        </div>
                                        <ul>{renderSupportOptions()}</ul>
                                    </div>
                                ) : null}
                                {isRouteActive({ path: '/contact-support' }) ? (
                                    <div className={classes.support}>
                                        <div className="HeaderLink__supportText">
                                            <FastLink to="/contact-support" className="HeaderLink__link">
                                                Support
                                            </FastLink>
                                        </div>
                                    </div>
                                ) : null}
                                <RenderForBrands brands={['ventra']}>
                                    <div className={classes.purchase}>
                                        <div className="HeaderLink__supportText">
                                            <button
                                                type="button"
                                                className="HeaderLink__link"
                                                onKeyDown={(e) => (e.key === ' ' ? togglePurchaseDropdown('desktop') : '')}
                                            >
                                                Purchase
                                                <PhosphorIcons.Caret.Down.Bold secondary size={16} className="HeaderLink__chevron" />
                                            </button>
                                        </div>
                                        <ul>{renderPurchaseOptions()}</ul>
                                    </div>
                                </RenderForBrands>
                                <RenderForBrands brands={['ventra']}>
                                    <div className={classes.vipRewards}>
                                        <FastLink to="/vip-rewards">{vipRewardsReadableName}</FastLink>
                                    </div>
                                </RenderForBrands>
                            </>
                        ) : (
                            <button ref={hamburgerRef} className="HeaderLink--mobile" onClick={() => openSidenav()}>
                                <i className="icon icon-list" />
                            </button>
                        )}
                    </div>

                    <div className="HeaderShared__right">
                        <RenderForBrands brands={['ventra']}>
                            <HeaderSupportPIN />
                        </RenderForBrands>
                        {!['xs', 'sm'].includes(app_viewport) && renderForBrands(['ventra']) ? <Alerts /> : ''}

                        <div className={classes.billing}>
                            <FastLink to="/billing">
                                <i className="icon icon-billing-icon" />
                                <span className="text">Billing</span>
                            </FastLink>
                        </div>
                        <div className="HeaderLink--break"></div>
                        <Accounts />
                    </div>
                </section>
            </header>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => ({
    app_viewport: state.app.app_viewport
});

Header = connect(mapStateToProps)(Header);

Header = withHeaderRouter(Header);
Header = withBrandStore(Header);
Header = NXQuery.auth.userData.withData(Header);

// Render nothing instead of the Header if showNavigation is false
if (!showNavigation) Header = () => '';

export default React.memo(Header);
