import { useStore } from '@tanstack/react-store';
import classNames from 'classnames';
import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { RenderForBrands } from 'config/brandRenderer/component';
import { Check } from 'containers/orderingForm/checkIcon';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import { ExclamationMark } from 'containers/orderingForm/exclamationMarkIcon';
import { InfoBubble } from 'containers/orderingForm/infoBubble';
import { ConfigureDomainsButton } from 'containers/orderingForm/pages/configurationPage/configureDomainsList/configureDomainsButton';
import { OrderingFormStore } from 'containers/orderingForm/store';
import type { Item } from 'containers/orderingForm/types';
import _ from 'lodash';
import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';
import { truncateText } from 'utilities/methods/commonActions';
import { NXQuery } from 'utilities/query';
import { UserPreferences } from 'utilities/UserPreferences';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ConfigureDomainsListItem = React.FC<ConfigureDomainsListItemProps>;
type ConfigureDomainsListItemProps = {
    item: Item;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ConfigureDomainsListItem: ConfigureDomainsListItem = ({ item }) => {
    /***** STATE *****/
    const [vipSitesDomain] = UserPreferences.useLocalStorage('CART_VIP_SITES_DOMAIN_NAME');

    /***** HOOKS *****/
    const isMobile = ViewportManager.useAppMediaViewport(['xs', 'sm']);
    const truncateLength = isMobile ? 25 : 35;
    const { cartToken } = useOrderingFormContext();
    const { data: is_user_detail_completed } = NXQuery.auth.login.checkToken.useSelectSuspenseQuery(void 0, ({ data }) => {
        return data.attributes.is_user_detail_completed;
    });
    const isPremiumAcknowledged = useStore(OrderingFormStore, (state) => !!state.isPremiumAcknowledgedMap?.[item.uuid]);

    /***** QUERIES *****/
    const { data: product_data } = NXQuery.orderingForm.getProducts.useSuspenseQuery({ ids: [item.id] });
    const { data: cart_data } = NXQuery.orderingForm.cart.cartId.getCart.useQuery(cartToken);

    const savedDetails = cart_data?.cart.items?.[0]?.domain_contact_fields;
    const usingSaved = _.isEqual(item?.domain_contact_fields, savedDetails);

    // Find IDs for 'Eligibility Number' and 'Eligibility Type' in product_data custom fields
    const productCustomFields = product_data?.[0]?.attributes?.custom_fields || [];
    const eligibilityNumberId = productCustomFields.find((field) => field.name === 'Eligibility Number')?.id;
    const eligibilityTypeId = productCustomFields.find((field) => field.name === 'Eligibility Type')?.id;

    // Find the matching elements in item.custom fields so we can get the value
    const numberMatchingElement = item?.custom_fields?.find((field) => field.id === eligibilityNumberId);
    const typeMatchingElement = item?.custom_fields?.find((field) => field.id === eligibilityTypeId);

    const auEligibilityParams = {
        eligibility_number: numberMatchingElement?.value,
        eligibility_type: typeMatchingElement?.value,
    };

    const { data: domain_eligibility_data, isLoading: isEligibilityLoading } = NXQuery.domain.domainID.auEligibility.useQuery(auEligibilityParams);

    /***** FUNCTIONS *****/
    const eligibilityRequired = () => {
        switch (true) {
            case isEligibilityLoading:
                return <RequestLoader />;
            case productCustomFields?.length > item?.custom_fields?.length && item.order_type === 'register':
                return 'Eligibility required';
            case eligibilityNumberId && domain_eligibility_data?.status === 'OK':
            case !eligibilityNumberId && productCustomFields?.length > 0:
                return 'Eligibility met';
            case item?.epp === 'invalid epp':
                return 'EPP required';
            case item?.order_type === 'transfer' && item?.epp !== 'invalid epp':
                return 'EPP skipped';

            default:
                return;
        }
    };

    /***** RENDER HELPERS *****/
    const renderEligibilityTag = () => {
        if (eligibilityRequired() === 'Eligibility required' || eligibilityRequired() === 'EPP required') {
            return (
                <Flex align="center">
                    <ExclamationMark size={15} />
                    <Text size--m> {eligibilityRequired()}</Text>
                </Flex>
            );
        }

        if (eligibilityRequired() === 'Eligibility met' || eligibilityRequired() === 'EPP skipped')
            return (
                <Flex align="center">
                    <Check size={15} />
                    <Text size--m> {eligibilityRequired()}</Text>
                </Flex>
            );
    };

    const isVipSitesDomain = vipSitesDomain === item.name;

    const renderContactRequired = () => {
        if (Array.isArray(item?.domain_contact_fields)) {
            if (is_user_detail_completed) {
                return (
                    <Flex align="center">
                        <Check size={15} />
                        <Text size--m>Using account details</Text>
                    </Flex>
                );
            }
            return (
                <Flex align="center">
                    <ExclamationMark size={15} />
                    <Text size--m>Contact details required</Text>
                </Flex>
            );
        }
        if (usingSaved) {
            return (
                <Flex align="center">
                    <Check size={15} />
                    <Text size--m>Using saved contact details</Text>
                </Flex>
            );
        }
        return;
    };

    const renderPremiumAcknowledgementTag = () => {
        if (item?.is_premium) {
            if (!isPremiumAcknowledged) {
                return (
                    <Flex align="center">
                        <ExclamationMark size={15} />
                        <Text size--m>Acknowledgment required</Text>
                    </Flex>
                );
            }

            return (
                <Flex align="center">
                    <Check size={15} />
                    <Text size--m> Premium acknowledged</Text>
                </Flex>
            );
        }
    };

    /***** RENDER *****/
    return (
        <li className={isVipSitesDomain ? 'configureDomainsListContainer' : ''}>
            {isVipSitesDomain && (
                <Text white size--custom={isMobile ? 13 : 15} align--center>
                    This domain will be used for your VIPsites website
                </Text>
            )}
            <Flex
                direction={isMobile ? 'column' : 'row'}
                align="center"
                gap={isMobile ? 1 : 0}
                className={classNames('configureDomainsList__listItem', {
                    'configureDomainsList__listItem--needEligibilityInfo': eligibilityRequired() === 'Eligibility required',
                })}
            >
                <Flex direction="column" gap={0} fullWidth>
                    <Flex align="center">
                        <Text size--xm bold>
                            {truncateText(item.name.toLowerCase().split('.')[0], truncateLength, '.')}
                            {item.product_name}
                        </Text>
                        {item?.is_premium && <InfoBubble label="Premium" />}
                    </Flex>

                    <RenderForBrands brands={['ventra']}>{renderContactRequired()}</RenderForBrands>

                    {renderEligibilityTag()}
                    {renderPremiumAcknowledgementTag()}
                </Flex>
                <Flex justify="end">
                    <ConfigureDomainsButton item={item} eligibilityRequired={eligibilityRequired} />
                </Flex>
            </Flex>
        </li>
    );
};
