import { FolderLink } from 'components/Intaserve/intaserveSidebar/components/navigation/folderLink';
import { SidebarFolder } from 'components/Intaserve/intaserveSidebar/components/navigation/sidebarFolder';
import { SidebarLink } from 'components/Intaserve/intaserveSidebar/components/navigation/sidebarLink';

export const IntaserveSidebar = {
    Navigation: {
        Folder: Object.assign(SidebarFolder, {
            Link: FolderLink
        }),
        Link: SidebarLink
    }
};
