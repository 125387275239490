import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ConditionalFontSizes = {
    folderFontSize: number;
    folderIconSize: number;
    folderLinkSize: number;
    accountNavIconSize: number;
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useConditionalFontSize = () => {
    return ViewportManager.useAppMediaViewport('md', (isTablet): ConditionalFontSizes => {
        if (isTablet) {
            return {
                folderFontSize: 15,
                folderIconSize: 20,
                folderLinkSize: 13,
                accountNavIconSize: 10
            };
        }

        return {
            folderFontSize: 16,
            folderIconSize: 26,
            folderLinkSize: 14,
            accountNavIconSize: 11
        };
    });
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
