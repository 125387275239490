import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { InfoBubble } from 'containers/orderingForm/infoBubble';
import { TransferSuggestionButton } from 'containers/orderingForm/pages/transferPage/transferSuggestions/transferSuggestionButton';
import type { DomainSuggestions } from 'containers/orderingForm/types';
import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';
import { truncateText } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Suggestion = React.FC<SuggestionProps>;
type SuggestionProps = {
    suggestion: DomainSuggestions;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Suggestion: Suggestion = ({ suggestion }) => {
    /***** HOOKS *****/
    const isMobile = ViewportManager.useAppMediaViewport(['xs', 'sm']);
    const truncateLength = isMobile ? 30 : 30;

    /***** RENDER *****/
    return (
        <li>
            {isMobile ? (
                <Flex direction="column" gap={0} fullWidth>
                    <Text size--xm bold>
                        {truncateText(suggestion.domain.toLowerCase(), truncateLength, '...')}
                        {suggestion.tld}
                    </Text>
                    <Flex align="start" justify="between" className="recommendedDomains__information">
                        <Flex align="center">
                            <Text>{`$${suggestion.price[0]?.price}/yr`}</Text>
                            {suggestion?.premium && <InfoBubble label="Premium" />}
                        </Flex>
                        <TransferSuggestionButton suggestion={suggestion} />
                    </Flex>
                </Flex>
            ) : (
                <Flex align="center" className="recommendedDomains__information">
                    <Flex direction="column" gap={0} fullWidth>
                        <Text size--xm bold>
                            {truncateText(suggestion.domain.toLowerCase(), truncateLength, '...')}
                            {suggestion.tld}
                        </Text>
                        <Flex align="center">
                            <Text>{`$${suggestion.price[0]?.price}/yr`}</Text>
                            {suggestion?.premium && <InfoBubble label="Premium" />}
                        </Flex>
                    </Flex>
                    <TransferSuggestionButton suggestion={suggestion} />
                </Flex>
            )}
        </li>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
