import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import { RegistrationButton } from 'containers/orderingForm/pages/registerPage/registrationButton';
import 'containers/orderingForm/pages/transferPage/transferAvailabilityConfirmation/transferErrorConfirmation/_transferErrorConfirmation.scss';
import type { DomainData } from 'containers/orderingForm/types';
import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';
import { truncateText } from 'utilities/methods/commonActions';
import { NXQuery } from 'utilities/query';

type TransferErrorConfirmation = React.FC<TransferErrorConfirmationProps>;
type TransferErrorConfirmationProps = {
    transferDomainData: DomainData;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const TransferErrorConfirmation: TransferErrorConfirmation = ({ transferDomainData }) => {
    /***** HOOKS *****/
    const { domainData: registerDomainData } = useOrderingFormContext();
    const isMobile = ViewportManager.useAppMediaViewport(['xs', 'sm']);

    const truncateLength = isMobile ? 30 : 40;

    /***** QUERIES *****/
    const { data: domain_transfer_availibility_data, isLoading: isDomainTransferAvailabilityLoading } =
        NXQuery.domain.availability.useQuery(transferDomainData);
    const { data: domain_register_availibility_data, isLoading: isDomainRegisterAvailabilityLoading } =
        NXQuery.domain.availability.useQuery(registerDomainData);

    const canRegisterDomain = domain_register_availibility_data?.[0]?.available === true;
    const canTransferDomain = domain_transfer_availibility_data?.[0]?.available === true;

    /***** RENDER HELPERS *****/
    const renderErrorContent = () => {
        switch (true) {
            case isDomainTransferAvailabilityLoading || isDomainRegisterAvailabilityLoading:
                return <RequestLoader height={15} />;
            case canRegisterDomain:
                return <RegistrationButton domainAvalibility={domain_register_availibility_data} />;

            case !canRegisterDomain && !canTransferDomain:
            default:
                return;
        }
    };

    /***** RENDER *****/
    return (
        <NXBox className="transferErrorConfirmation__container">
            <Padding xy={1}>
                <Text size--m align--center>
                    {!canRegisterDomain && !canTransferDomain ? 'This domain cannot be transfered' : 'This domain has not been registered'}
                </Text>
                <Flex fullWidth align="center" justify="between" className="transferErrorConfirmation__information">
                    <Text size--xm bold align--center>
                        {truncateText(domain_transfer_availibility_data?.[0].domain.toLowerCase(), truncateLength, '...')}
                        {domain_transfer_availibility_data?.[0].tld}
                    </Text>
                    <Flex>{renderErrorContent()}</Flex>
                </Flex>
            </Padding>
        </NXBox>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
