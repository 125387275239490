import React from 'react';
import { ModalViewportContext } from 'utilities/classes/viewportManager/context';
import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';
import './_ViewportContainer.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ViewportContainer = React.FC<{
    children: React.ReactNode;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
// prettier-ignore
const _ModalViewportContainer: ViewportContainer = ({ children }) => {
    const registerElementAsContainer = ViewportManager.useRegisterElementAsContainer("overlayContainer");

    /***** RENDER *****/
    return (
        <ModalViewportContext.Provider value>
            <div ref={registerElementAsContainer} className="ViewportContainer__lightbox">
                {children}
            </div>
        </ModalViewportContext.Provider>
    );
};

export const ModalViewportContainer = React.memo(_ModalViewportContainer);
