import { zodResolver } from '@hookform/resolvers/zod';
import { useStore } from '@tanstack/react-store';
import { SolidButton } from 'components/Buttons/SolidButton';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { AU_NZ_STATES, NON_ELIGIBILITY_FIELD_VALUES } from 'containers/orderingForm/consts';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import { ExclamationMark } from 'containers/orderingForm/exclamationMarkIcon';
import type { EditFormValues } from 'containers/orderingForm/orderingFormInputs/types';
import { PageHeader } from 'containers/orderingForm/pageHeader';
import 'containers/orderingForm/pages/editItemConfigPage/_editItemConfigPage.scss';
import { EligibilityFormFields } from 'containers/orderingForm/pages/editItemConfigPage/eligibilityFormFields';
import { EppFormFields } from 'containers/orderingForm/pages/editItemConfigPage/eppFormFields';
import { FormOptionToggles } from 'containers/orderingForm/pages/editItemConfigPage/formOptionToggles';
import { formSchema } from 'containers/orderingForm/pages/editItemConfigPage/formSchema';
import { RegistrationFormFields } from 'containers/orderingForm/pages/editItemConfigPage/registrationFormFields';
import { OrderingFormStore } from 'containers/orderingForm/store';
import type { Item } from 'containers/orderingForm/types';
import { getPayload } from 'containers/orderingForm/utils';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceValue } from 'usehooks-ts';
import { truncateText } from 'utilities/methods/commonActions';
import { NXQuery } from 'utilities/query';
import { UserPreferences } from 'utilities/UserPreferences';
import { PremiumFormFields } from './premiumFormFields';
import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type EditItemConfigPage = React.FC<EditItemConfigPageProps>;
type EditItemConfigPageProps = {
    name: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const EditItemConfigPage: EditItemConfigPage = ({ name }) => {
    /***** STATE *****/
    const [skipEppForNow, setSkipEppForNow] = useState(false);
    const [dynamicFields, setDynamicFields] = useState<Array<{ name: string }>>([]);
    const [vipSitesDomain, setVipSitesDomain] = UserPreferences.useLocalStorage('CART_VIP_SITES_DOMAIN_NAME');

    /***** HOOKS *****/
    const { data: is_user_detail_completed } = NXQuery.auth.login.checkToken.useSelectSuspenseQuery(void 0, ({ data }) => {
        return data.attributes.is_user_detail_completed;
    });
    const isSmallMobile = ViewportManager.useAppMediaViewport(['xs']);
    const { activeBrand } = useBrandStore();
    const { data: user } = NXQuery.auth.userData.useSelectSuspenseQuery(void 0, ({ data }) => {
        return activeBrand === 'ventra' ? data.attributes : null;
    });
    const { currentItem, goBackPage, goToPage, currentPage, cartToken } = useOrderingFormContext();
    const isPremiumAcknowledged = useStore(OrderingFormStore, (state) => state.isPremiumAcknowledgedMap?.[currentItem?.uuid]);

    const { data: cart_data } = NXQuery.orderingForm.cart.cartId.getCart.useQuery(cartToken);
    const cartContactFields = cart_data?.cart?.items?.[0]?.domain_contact_fields;
    const currentContactFields = currentItem?.domain_contact_fields;
    const defaultValues = {
        registrationPeriod: String(currentItem?.billing_cycle_id),
        contactDetails: defaultContactDetails(),
        eligibilityType: currentItem?.eligibilityType ?? 'ABN',
        eligibilityNumber: currentItem?.eligibilityNumber ?? '',
        autoRenew: isNotUndefined(currentItem?.auto_renew, true),
        idProtection: isNotUndefined(Boolean(currentItem?.id_protection), activeBrand === 'intaserve' ? false : true),
        legalName: currentItem?.legalname ?? '',
        firstName: currentContactFields?.firstname ?? cartContactFields?.firstname ?? user?.firstname ?? '', //use cart_data
        lastName: currentContactFields?.lastname ?? cartContactFields?.lastname ?? user?.lastname ?? '',
        emailAddress: currentContactFields?.email ?? cartContactFields?.email ?? user?.email ?? '',
        phoneNumber: currentContactFields?.phone ?? cartContactFields?.phone ?? user?.phone ?? '',
        companyName: currentContactFields?.company_name ?? cartContactFields?.company_name ?? user?.company ?? '',
        address: currentContactFields?.address1 ?? cartContactFields?.address1 ?? user?.address1 ?? '',
        suburb: currentContactFields?.suburb ?? cartContactFields?.suburb ?? user?.city ?? '',
        country: currentContactFields?.country ?? cartContactFields?.country ?? user?.country ?? '',
        state:
            currentContactFields?.state ??
            cartContactFields?.state ??
            Object.keys(AU_NZ_STATES).find((state) => AU_NZ_STATES[state] === user?.state) ??
            '',
        postcode: currentContactFields?.postcode ?? cartContactFields?.postcode ?? user?.postcode ?? '',
        epp: currentItem?.epp && currentItem?.epp !== 'invalid epp' ? currentItem?.epp : '',
        isUsedForVipSites: vipSitesDomain === currentItem?.name,
    };

    const methods = useForm<EditFormValues>({
        defaultValues,
        resolver: zodResolver(formSchema(dynamicFields)),
    });
    const {
        handleSubmit,
        watch,
        register,
        setValue,
        formState: { errors, isValid },
        clearErrors
    } = methods;
    const [deboucedEligibilityNumber] = useDebounceValue(watch('eligibilityNumber'), 500);

    const [deboucedEppCode] = useDebounceValue(watch('epp'), 500);
    const eligibilityTypeValue = watch('eligibilityType');
    const eligibilityNumberValue = watch('eligibilityNumber');

    /***** DERIVED STATE *****/
    const isUsingAccountDetails = watch('contactDetails') === 'currentDetails';

    const eppValidationParams = {
        domain: currentItem?.name,
        eppCode: deboucedEppCode,
    };

    const productParams = {
        ids: [currentItem?.id ?? 0],
    };

    const auEligibilityParams = {
        eligibility_number: deboucedEligibilityNumber || currentItem?.custom_fields?.find((field) => field.name === 'Eligibility Number'),
        eligibility_type: watch('eligibilityType') || currentItem?.custom_fields?.find((field) => field.name === 'Eligibility Type'),
    };

    /***** QUERIES *****/
    const { data: product_data } = NXQuery.orderingForm.getProducts.useQuery(productParams);
    const fieldsThatRequireEligibility =
        currentItem?.order_type === 'register'
            ? product_data?.[0]?.attributes?.custom_fields?.filter(
                (field) => field.name === 'Eligibility Number' && !NON_ELIGIBILITY_FIELD_VALUES.includes(eligibilityTypeValue)
            )
            : [];

    const { data: domain_eligibility_data } = NXQuery.domain.domainID.auEligibility.useQuery(auEligibilityParams);

    const { mutate: mutateEditItem } = NXQuery.orderingForm.cart.cartId.editItem.useMutation(cartToken as string);
    const { mutateAsync: mutateGetProducts } = NXQuery.orderingForm.mutateGetProducts.useMutation();
    const { data: epp_validation_data, isLoading: isEppValidationDataLoading } = NXQuery.domain.eppValidation.useQuery(eppValidationParams);

    /***** EFFECTS *****/
    useEffect(() => {
        if (currentItem) {
            const addedFields = {};
            const dynamicFieldNames: { name: string }[] = [];
            product_data?.[0]?.attributes?.custom_fields?.forEach((field) => {
                if (currentItem?.order_type === 'transfer') return;
                const name = field.name
                    .split(' ')
                    .map((word: string, index: number) => (index === 0 ? word.toLowerCase() : word))
                    .join('');

                const currentItemValue = currentItem?.custom_fields?.find((currentItemField) => currentItemField.id === field.id);
                addedFields[name] = currentItemValue?.value;
                if (name === 'eligibilityType' && !currentItemValue) addedFields[name] === 'ABN';
                dynamicFieldNames.push(name);
            });

            setDynamicFields(dynamicFieldNames);
            const filteredFields = Object.fromEntries(Object.entries(addedFields).filter(([, value]) => value !== undefined));
            methods.reset({ ...defaultValues, ...filteredFields, legalName: domain_eligibility_data?.entityName ?? '' });
        }
    }, [currentItem, currentPage]);

    useEffect(() => {
        if (domain_eligibility_data?.status === 'OK') {
            setValue('legalName', domain_eligibility_data.entityName);
        }
    }, [domain_eligibility_data?.status, setValue]);

    /***** FUNCTIONS *****/
    const handleOnSubmit = (formData) => {
        if (activeBrand === 'intaserve') formData.autoRenew = true;

        const payload = [getPayload({ formData, item: currentItem, customFields: product_data?.[0]?.attributes?.custom_fields ?? null })];

        mutateEditItem(payload, {
            onSuccess: (response) => {
                if (activeBrand === 'ventra') {
                    // If "use for VIPsites" was checked, change the VIPsites domain to this item
                    if (formData.isUsedForVipSites) {
                        setVipSitesDomain(currentItem.name);
                    }

                    const responseItems = response?.data.cart.items;
                    responseItems.forEach(async (domain: Item) => {
                        const item_data = await mutateGetProducts(domain.id);
                        if (!item_data.data?.[0]?.attributes.custom_fields && Array.isArray(domain.domain_contact_fields)) {
                            mutateEditItem([getPayload({ formData, item: domain, customFields: null })]);
                        }
                    });
                }

                goToPage('configuration');
            },
        });
    };

    function defaultContactDetails() {
        // If some manually entered contacts already exist for this domain then we want to use "newDetails" (the domain contacts on the cart will be an object in this case)
        if (!Array.isArray(currentContactFields)) {
            return 'newDetails';
        }

        // If there aren't any manually entered details, but the user has a full set of details on their account, use those
        if (is_user_detail_completed) {
            return 'currentDetails';
        }

        // If there are no manually entered details, and the user doens't have a full set of account details, fallback to using new details
        return 'newDetails';
    }

    /***** RENDER HELPERS *****/
    const shouldUpdateButtonBeDisabled = () => {
        switch (true) {
            case fieldsThatRequireEligibility?.length && domain_eligibility_data?.status !== 'OK':
            case Object.entries(errors)?.length && !isUsingAccountDetails:
            case isEppValidationDataLoading:
            case (currentItem?.epp && deboucedEppCode === '') || (epp_validation_data?.reason === 'epp_invalid' && skipEppForNow === false):
            case currentItem?.is_premium && !isPremiumAcknowledged:
            case NON_ELIGIBILITY_FIELD_VALUES.includes(eligibilityTypeValue) && eligibilityNumberValue === '':
                return true;

            default:
                return false;
        }
    };

    const isUpdateButtonDisabled = shouldUpdateButtonBeDisabled();

    function isNotUndefined(value: boolean | undefined, if_undefined: boolean) {
        if (value !== undefined) return value;
        return if_undefined;
    }

    /***** RENDER *****/
    if (name !== currentPage) return null;
    return (
        <FormProvider {...methods}>
            <PageHeader
                title={truncateText(currentItem?.name.split('.')[0], isSmallMobile ? 20 : 25, '.') + currentItem?.product_name}
                backButton
                backOnClick={() => {
                    goToPage('configuration');
                }}
            />
            <Flex className="editItemConfig">
                <form
                    onSubmit={handleSubmit((formData) => {
                        return handleOnSubmit(formData);
                    })}
                    className="editItemConfig__itemForm"
                >
                    {/* Form fields */}
                    <RegistrationFormFields register={register} watch={watch} errors={errors} />
                    {activeBrand === 'ventra' && currentItem?.uuid === cart_data?.cart?.items[0].uuid && (
                        <Flex direction="column" className="editItemConfig__InfoNotice">
                            <Flex align="center">
                                <ExclamationMark size={15} />
                                <Text size--xm bold>
                                    Please Note
                                </Text>
                            </Flex>
                            <Text size--s>
                                The registrant contact details above will be used for your VIPcontrol account and applied to all other domains in your
                                order. You can update these details later.
                            </Text>
                        </Flex>
                    )}
                    {product_data?.[0]?.attributes?.custom_fields?.length > 0 && (
                        <EligibilityFormFields
                            productData={product_data}
                            auEligibilityParams={auEligibilityParams}
                            register={register}
                            watch={watch}
                            errors={errors}
                        />
                    )}
                    {currentItem?.order_type === 'transfer' && (
                        <EppFormFields
                            register={register}
                            watch={watch}
                            errors={errors}
                            setSkipEppForNow={setSkipEppForNow}
                            skipEppForNow={skipEppForNow}
                        />
                    )}
                    {currentItem?.is_premium && <PremiumFormFields />}

                    <FormOptionToggles />
                    {/* Form buttons */}
                    <Padding top={10}>
                        <Flex fullWidth justify="end" gap={3}>
                            <SolidButton type="onClick" onClick={() => goBackPage()} className="editItemConfig__formButton--cancel">
                                <Text semiBold size--s color="S_color_text_default_base">
                                    Cancel
                                </Text>
                            </SolidButton>
                            <SolidButton type="submit" className="editItemConfig__formButton--update" disabled={isUpdateButtonDisabled}>
                                <Text semiBold size--s color="S_color_text_default_base">
                                    {activeBrand === 'intaserve' ? 'Update' : 'Next Step'}
                                </Text>
                            </SolidButton>
                        </Flex>
                    </Padding>
                </form>
            </Flex>
        </FormProvider>
    );
};
