import type { FolderLinkProps } from 'components/Intaserve/intaserveSidebar/components/navigation/types';
import { createContext } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export type NavigationFolderContext = {
    setLinks: React.Dispatch<React.SetStateAction<Set<FolderLinkProps>>>;
    links: Set<FolderLinkProps>;
};

export const NavFolderContext = createContext<NavigationFolderContext | undefined>(undefined);
