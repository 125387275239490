import classNames from 'classnames';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import 'containers/orderingForm/footer/cartList/_cartList.scss';
import { RemoveButton } from 'containers/orderingForm/footer/cartList/removeButton';
import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';
import { truncateText } from 'utilities/methods/commonActions';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type CartList = React.FC<CartListProps>;
type CartListProps = {
    isCartListOpen: boolean;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CartList: CartList = ({ isCartListOpen }) => {
    /***** HOOKS *****/
    const { cartToken } = useOrderingFormContext();
    const isMobile = ViewportManager.useAppMediaViewport(['xs', 'sm']);
    const truncateLength = isMobile ? 20 : 25;

    /***** QUERIES *****/
    const { data: cart_data } = NXQuery.orderingForm.cart.cartId.getCart.useQuery(cartToken);

    const cartListContainerClassNames = classNames('cartList__container', {
        'cartList__container--open': isCartListOpen
    });

    /***** RENDER *****/
    return (
        <ul className={cartListContainerClassNames}>
            {cart_data?.cart?.items.map((item, index: number) => {
                return (
                    <li key={index} className="cartList__cartItem">
                        <Flex direction="column" gap={0}>
                            <Text bold>
                                {truncateText(item.name.split('.')[0].toLowerCase(), truncateLength, '.')}
                                {item.product_name}
                            </Text>
                            <Text>{`$${item.total_amount}/yr`}</Text>
                        </Flex>
                        <RemoveButton item={item} />
                    </li>
                );
            })}
        </ul>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
