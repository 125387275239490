import { Link, useMatches } from '@tanstack/react-router';
import type { IconifyIconNamespace } from 'components/Icons/types';
import 'components/Intaserve/intaserveSidebar/components/navigation/sidebarLink/_sidebarLink.scss';
import { useNavContext } from 'components/Intaserve/intaserveSidebar/contexts/navContext/useNavContext';
import { useConditionalFontSize } from 'components/Intaserve/intaserveSidebar/hooks/useConditionalSize';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import React from 'react';
import type { RoutePaths } from 'router/types';
import { isRouteActive } from 'router/utils/isRouteActive';
import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SidebarLink = React.FC<SidebarLinkProps>;
type SidebarLinkProps = {
    /**
     *  Text to show as the link.
     */
    title: string;
    
    /**
     *  The route you want the link to go to.
     */
    path: RoutePaths;
    
    /**
     *  The icon that sits to the left of the link.
     */
    Icon: IconifyIconNamespace.Component & { Fill: IconifyIconNamespace.Component };
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SidebarLink: SidebarLink = ({ title, path, Icon }) => {
    /***** HOOKS *****/
    const { setOpenFolder, setIsMobileSidebarOpen } = useNavContext();
    const { folderFontSize, folderIconSize } = useConditionalFontSize();
    const isMobile = ViewportManager.useAppMediaViewport(['xs', 'sm']);
    const isRouteMatch = useMatches({ select: (matches) => matches.some((match) => path === match.fullPath) });

    /***** FUNCTIONS *****/
    function handleOnClick() {
        if (isMobile) setIsMobileSidebarOpen(false);
        setOpenFolder('');
    }

    // IF the route matches the current route THEN show the fill icon
    const iconClass = !isRouteMatch ? 'sidebarLink__icon' : 'sidebarLink__icon--hidden';
    const iconFillClass = !isRouteMatch ? 'sidebarLink__iconFill--hidden' : 'sidebarLink__iconFill';

    /***** RENDER *****/
    if (!isRouteActive({ path })) return null;

    return (
        <Link activeProps={{ className: 'sidebarLink__link--isOpen' }} onClick={handleOnClick} to={path} className="sidebarLink__link">
            <Padding paddingOnly xy={3}>
                <Flex align="center" justify="between" fullHeight>
                    <Flex gap={3}>
                        <Icon className={iconClass} size={folderIconSize} />
                        <Icon.Fill className={iconFillClass} size={folderIconSize} />
                        <Text size--custom={folderFontSize} bold className="sidebarLink__textColorTransition">
                            {title}
                        </Text>
                    </Flex>
                </Flex>
            </Padding>
        </Link>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
