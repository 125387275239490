import { useRegistrationOrderingFormContext } from 'App/components/templates/contexts/registrationOrderingFormContext/useRegistrationOrderingFormContext';
import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { RenderForBrands } from 'config/brandRenderer/component';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import { PageHeader } from 'containers/orderingForm/pageHeader';
import 'containers/orderingForm/pages/confirmOrderPage/_confirmOrderPage.scss';
import { CartItem } from 'containers/orderingForm/pages/confirmOrderPage/cartItem';
import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ConfirmOrderPage = React.FC<ConfirmOrderPageProps>;
type ConfirmOrderPageProps = {
    name: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ConfirmOrderPage: ConfirmOrderPage = ({ name }) => {
    /***** HOOKS *****/
    const { goToPage, currentPage, cartToken } = useOrderingFormContext();
    const { isRegistration } = useRegistrationOrderingFormContext();
    const isMobile = ViewportManager.useAppMediaViewport(['xs', 'sm']);

    /***** QUERIES *****/
    const { data: cart_data, isLoading: isCartDataLoading } = NXQuery.orderingForm.cart.cartId.getCart.useQuery(cartToken);

    /***** FUNCTIONS *****/
    const handleGoBack = () => {
        switch (true) {
            case cart_data?.cart?.items?.length:
                return goToPage('configuration');
            case !isRegistration:
                return goToPage('transfer');

            default:
                return goToPage('register');
        }
    };

    /***** RENDER HELPERS *****/
    const renderSummary = () => {
        return (
            <RenderForBrands brands={['ventra']}>
                <div>
                    <Flex direction="column" className="confirmOrderPage__summaryContainer">
                        <Flex justify="between" fullWidth>
                            <Text>Subtotal</Text>
                            <Text semiBold>${cart_data?.cart.subtotal_amount}</Text>
                        </Flex>
                        {cart_data?.cart.total_discount_amount && cart_data?.cart.total_discount_amount !== '0.00' && (
                            <Flex justify="between" fullWidth>
                                <Text>Discount Amount</Text>
                                <Text semiBold>${cart_data?.cart.total_discount_amount}</Text>
                            </Flex>
                        )}
                        <Flex justify="between" fullWidth>
                            <Text bold uppercase>
                                Total Due Today
                            </Text>
                            <Text semiBold primary>
                                ${cart_data?.cart.total_amount}
                            </Text>
                        </Flex>
                    </Flex>
                </div>
            </RenderForBrands>
        );
    };

    /***** RENDER *****/
    if (name !== currentPage) return null;

    return (
        <>
            <PageHeader title="Your Cart" backButton backOnClick={() => handleGoBack()} />
            {isCartDataLoading ? (
                <RequestLoader />
            ) : (
                <Flex direction="column" gap={5}>
                    <Flex direction="column" className="confirmOrderPage__itemsContainer">
                        {cart_data?.cart.items.map((item) => {
                            return <CartItem key={item.uuid} item={item} />;
                        })}
                        {isMobile && renderSummary()}
                    </Flex>
                    {!isMobile && renderSummary()}
                </Flex>
            )}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
