import { PhosphorIcons } from 'components/Icons/Phosphor';
import { AccountSubmenuLink } from 'components/Intaserve/intaserveSidebar/components/accountSubmenu/accountSubmenuLink';
import { AccountSubmenuLogout } from 'components/Intaserve/intaserveSidebar/components/accountSubmenu/logout';
import { useConditionalFontSize } from 'components/Intaserve/intaserveSidebar/hooks/useConditionalSize';
import Avatar from 'components/Placeholders/Avatar';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { NXQuery } from 'utilities/query';
import './_accountSubmenu.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const AccountSubmenu = () => {
    /***** QUERIES *****/
    const { data: userData } = NXQuery.auth.userData.useSelectSuspenseQuery(void 0, ({ data }) => data.attributes);
    const { firstname, lastname, email } = userData;

    /***** HOOKS *****/
    const { folderFontSize } = useConditionalFontSize();

    /***** RENDER *****/
    return (
        <>
            <Flex direction="column" fullWidth gap={1} justify="center" align="center" className="accountSubmenu__header">
                <Avatar size={50} fontSize={20} fullname={`${firstname} ${lastname}`} />
                <Text size--custom={folderFontSize} color="S_color_text_default_base">
                    {firstname} {lastname}
                </Text>
                <Text size--sm lead--0 color="S_color_text_default_base">
                    {email}
                </Text>
            </Flex>
            <Flex direction="column" className="accountSubmenu__links" gap={0}>
                <AccountSubmenuLink
                    key="Account"
                    link="/my-services/hosting/$serviceId/reseller"
                    Icon={PhosphorIcons.GearFine}
                    label="Account Settings"
                />
                <AccountSubmenuLink key="Billing" link="/billing" Icon={PhosphorIcons.ShieldChevron} label="Billing Details" />
                <AccountSubmenuLink key="Appearance" link="/contact-support" Icon={PhosphorIcons.ShieldChevron} label="Appearance" />
                <AccountSubmenuLink key="Refer" link="" Icon={PhosphorIcons.ShieldChevron} label="Refer a Friend" />
                <AccountSubmenuLogout key="Logout" Icon={PhosphorIcons.ShieldChevron} label="Logout" />
            </Flex>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
