import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import { RegistrationButton } from 'containers/orderingForm/pages/registerPage/registrationButton';
import { Pricing } from 'containers/orderingForm/pricing';
import type { DomainSuggestions } from 'containers/orderingForm/types';
import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';
import { truncateText } from 'utilities/methods/commonActions';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Suggestions = {
    suggestedItems: Array<DomainSuggestions> | null;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Suggestions = ({ suggestedItems }: Suggestions) => {
    /***** HOOKS *****/
    const isMobile = ViewportManager.useAppMediaViewport(['xs', 'sm']);
    const truncateLength = isMobile ? 25 : 30;
    const { domainData } = useOrderingFormContext();
    const { activeBrand } = useBrandStore();

    /***** QUERIES *****/
    const { isLoading: isDomainSuggestionsLoading } = NXQuery.domain.suggestion.useInfiniteQuery(domainData);

    /***** RENDER *****/
    if (isDomainSuggestionsLoading) {
        return (
            <Flex justify="center" align="center" className="recommendedDomains__informationContainer">
                <RequestLoader />
            </Flex>
        );
    }

    if (suggestedItems?.length) {
        return (
            <ul className="recommendedDomains__suggestions">
                {suggestedItems.map((suggestion, index) => {
                    return (
                        <li key={index}>
                            <Flex align="center" className="recommendedDomains__information">
                                <Flex direction="column" gap={0} fullWidth>
                                    <Text size--xm bold>
                                        {truncateText(suggestion.domain.toLowerCase(), truncateLength, '...')}
                                        {suggestion.tld}
                                    </Text>
                                    {!suggestion.available && activeBrand === 'ventra' ? null : (
                                        <Pricing
                                            premium={suggestion?.premium}
                                            price={suggestion.price[0]?.price ?? ''}
                                            oldPrice={suggestion.price[0]?.old_price}
                                        />
                                    )}
                                </Flex>

                                <RegistrationButton isSuggestionButton suggestion={suggestion} />
                            </Flex>
                        </li>
                    );
                })}
            </ul>
        );
    }

    return (
        <Flex justify="center" className="recommendedDomains__informationContainer">
            <Text size--s align--center>
                Enter a domain above to get started!
            </Text>
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
