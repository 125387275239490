/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useStore } from '@tanstack/react-store';
import { useModalViewportContext } from 'utilities/classes/viewportManager/context';
import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Function that returns the current viewport or a boolean if the viewport matches the provided viewport
 *
 * @deprecated use `ViewportManager.useAppViewport()` instead
 *
 * @note This hook will automatically treat the entire viewport as the viewport if it is inside a modal,
 * otherwise it will treat the viewport as the viewport of the container like usual.
 */
export const useAppViewport: useAppViewport = (match, invert) => {
    /***** HOOKS *****/
    const isInsideOverlayViewportContainer = useModalViewportContext();
    const viewport = useStore(ViewportManager.store, ({ containerViewport, overlayContainerViewport }) => {
        const viewport = isInsideOverlayViewportContainer ? overlayContainerViewport : containerViewport;

        return viewport ?? 'xs';
    });

    /***** HOOK RESULTS *****/
    if (!match) {
        return viewport as any;
    }

    if (Array.isArray(match)) {
        const isMatched = match.includes(viewport);
        return (invert ? !isMatched : isMatched) as any;
    }

    return (invert ? viewport !== match : viewport === match) as any;
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
