import { OutlineButton } from 'components/Buttons/OutlineButton';
import { SolidButton } from 'components/Buttons/SolidButton';
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import RequestLoader from 'components/Loaders/Request';
import { NXTooltip } from 'components/NXTooltip';
import Switch from 'components/Switch';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import { InfoBubble } from 'containers/orderingForm/infoBubble';
import 'containers/orderingForm/pages/confirmOrderPage/cartItem/_cartItem.scss';
import type { Item } from 'containers/orderingForm/types';
import { useState } from 'react';
import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';
import { truncateText } from 'utilities/methods/commonActions';
import { NXQuery } from 'utilities/query';
import { UserPreferences } from 'utilities/UserPreferences';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type CartItem = React.FC<CartItemProps>;
type CartItemProps = {
    item: Item;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CartItem: CartItem = ({ item }) => {
    /***** STATE *****/
    const [vipSitesDomain] = UserPreferences.useLocalStorage('CART_VIP_SITES_DOMAIN_NAME');

    /***** HOOKS *****/
    const { cartToken, goToPage, setCurrentItem } = useOrderingFormContext();
    const isMobile = ViewportManager.useAppMediaViewport(['xs', 'sm']);
    const truncateLength = isMobile ? 25 : 40;
    const { activeBrand } = useBrandStore();
    const [componentLoading, setComponentLoading] = useState<keyof typeof item | null>(null);

    /***** CONSTS *****/
    const productParams = {
        ids: [item.id],
    };
    const isVentra = activeBrand === 'ventra';

    const domainData = {
        domain: item?.name.replace(item?.product_name, ''),
        action: item?.order_type,
        promos: [],
        tlds: [item?.product_name],
    };
    const EditComponent = isVentra ? OutlineButton : SolidButton;

    /***** QUERIES *****/
    const { data: domain_availability_data } = NXQuery.domain.availability.useQuery(domainData);
    const { data: product_data } = NXQuery.orderingForm.getProducts.useQuery(productParams);
    const { mutate: removeCartItem, isPending: isRemoveCartItemLoading } = NXQuery.orderingForm.cart.cartId.removeItems.useMutation();
    const { mutate: mutateEditItem } = NXQuery.orderingForm.cart.cartId.editItem.useMutation(cartToken as string);

    const selectedRegistrationPeriod =
        domain_availability_data?.[0]?.price.find((price) => price?.billing_cycle_id === item?.billing_cycle_id) ??
        domain_availability_data?.[0]?.price[0];

    const isIdProtectionAllowed = !!product_data?.[0]?.attributes?.meta?.find((item) => item?.identifier === 'allow_id_protection');

    /***** FUNCTIONS *****/
    const handleEditOnClick = () => {
        setCurrentItem(item);
        goToPage('editItemConfiguration');
    };

    const updateItem = (key: keyof typeof item, value: number | boolean) => {
        const payload = [
            {
                ...item,
                [key]: value,
                action: 'edit',
            },
        ];
        setComponentLoading(key);
        mutateEditItem(payload, {
            onSettled: () => setComponentLoading(null),
        });
    };

    /***** RENDER HELPERS *****/
    function renderContent() {
        return (
            <>
                <Text size--xm semiBold lead--none>
                    {truncateText(item.name.toLowerCase(), truncateLength, '...')}
                </Text>
                <Flex align="center" gap={1}>
                    {item?.order_type === 'transfer' ? (
                        <PhosphorIcons.ArrowFatLineRight size={15} className="cartItem__arrowIcon" />
                    ) : (
                        <PhosphorIcons.Globe size={15} className="cartItem__globeIcon" />
                    )}
                    <Text size--custom={15} secondary>
                        Domain Name {item?.order_type === 'register' ? 'Register' : 'Transfer'}
                    </Text>
                    {item?.is_premium && <InfoBubble label="Premium" />}
                </Flex>
            </>
        );
    }

    const isVipSitesDomain = vipSitesDomain === item.name;

    /***** RENDER *****/
    return (
        <Flex direction="column" className="cartItem__itemContainer">
            <Flex justify="between">
                <Flex direction="column" gap={0}>
                    {renderContent()}
                </Flex>
                <Flex>
                    {isVentra && item?.old_price && !item?.is_premium && (
                        <Text strikethrough secondary>
                            ${item?.old_price}
                        </Text>
                    )}
                    <NXTooltip
                        title={
                            isVentra && item?.old_price && !item.is_premium
                                ? 'Discount applies to the first year on new standard registrations only. Premium registrations are not included.'
                                : ''
                        }
                        tooltipClassName="cartItem__tooltipPopup"
                    >
                        <Text className={item?.old_price && isVentra && !item?.is_premium ? 'cartItem__textTooltip' : ''}>${item?.total_amount}</Text>
                    </NXTooltip>
                </Flex>
            </Flex>

            <Flex direction="column">
                {isIdProtectionAllowed && (
                    <Flex justify="between">
                        <Flex gap={1}>
                            <Text size--s semiBold>{`ID Protection is ${item?.id_protection ? 'enabled' : 'disabled'}`}</Text>
                            <NXTooltip
                                title={`By default ID Protection is ${isVentra ? 'enabled' : 'disabled'} on all eligible domain names.`}
                                className="cartItem__tooltip"
                                tooltipClassName="cartItem__tooltipPopup"
                            >
                                <PhosphorIcons.QuestionMark size={16} />
                            </NXTooltip>
                        </Flex>
                        {isVentra && (
                            <Switch
                                disabled={componentLoading === 'id_protection'}
                                switchvalue={item.id_protection}
                                onClick={() => updateItem('id_protection', !item.id_protection)}
                            />
                        )}
                    </Flex>
                )}
                <Flex justify="between">
                    <Flex gap={1}>
                        <Text size--s semiBold>
                            Auto Renew is {item.auto_renew ? 'enabled' : 'disabled'}
                        </Text>
                        <NXTooltip
                            title={
                                item.is_premium && isVentra
                                    ? 'Auto Renew is disabled for Premium Domains'
                                    : 'By default Auto Renew is enabled on all eligible domain names.'
                            }
                            className="cartItem__tooltip"
                            tooltipClassName="cartItem__tooltipPopup"
                        >
                            <PhosphorIcons.QuestionMark size={16} />
                        </NXTooltip>
                    </Flex>
                    {isVentra && (
                        <Switch
                            disabled={componentLoading === 'auto_renew' || item?.is_premium}
                            switchvalue={item.auto_renew}
                            onClick={() => updateItem('auto_renew', !item.auto_renew)}
                        />
                    )}
                </Flex>
                {isVentra && isVipSitesDomain && (
                    <Flex justify="between">
                        <Flex gap={1}>
                            <Text size--s semiBold>
                                This domain will be used for your VIPsites website
                            </Text>
                            <NXTooltip
                                title="This domain name will be assigned to your VIPSites Website Builder. You can assign this to another domain name in your cart by clicking the Edit button on another domain."
                                className="cartItem__tooltip"
                                tooltipClassName="cartItem__tooltipPopup"
                            >
                                <PhosphorIcons.QuestionMark size={16} />
                            </NXTooltip>
                        </Flex>
                    </Flex>
                )}
            </Flex>
            <Flex justify="between" align="center">
                {isVentra && !item?.is_premium && product_data?.[0] ? (
                    <SelectDropdown
                        className="cartItem__dropdown"
                        disabled={componentLoading === 'billing_cycle_id'}
                        preselectedOptionIndex={
                            product_data?.[0].attributes.price
                                .filter((price) => price.type === 'register')
                                .findIndex((price) => price.billing_cycle_id === item.billing_cycle_id) || 0
                        }
                        options={product_data?.[0].attributes.price
                            .filter((price) => price.type === 'register')
                            .map((price) => ({
                                label: price.billing_cycle_name,
                                value: price.billing_cycle_id,
                                onClick: () => updateItem('billing_cycle_id', price.billing_cycle_id),
                            }))}
                    />
                ) : (
                    item?.order_type === 'register' && (
                        <Flex fullWidth>
                            <Text size--s semiBold>
                                Registration Period: {selectedRegistrationPeriod?.name}
                            </Text>
                        </Flex>
                    )
                )}
                <Flex fullWidth justify="end">
                    <EditComponent
                        type="onClick"
                        color={isVentra ? 'primary' : 'secondary'}
                        disabled={isRemoveCartItemLoading}
                        onClick={handleEditOnClick}
                        className="cartItem__button--edit"
                    >
                        Edit
                    </EditComponent>

                    <SolidButton
                        type="onClick"
                        onClick={() => cartToken && removeCartItem({ itemId: item.uuid, cartToken })}
                        className="cartItem__button--remove"
                    >
                        {isRemoveCartItemLoading ? <RequestLoader height={15} /> : 'Remove'}
                    </SolidButton>
                </Flex>
            </Flex>
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
