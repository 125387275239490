import { useRouter } from '@tanstack/react-router';
import classNames from 'classnames';
import { logoutApp } from 'components/Header/action';
import type { IconifyIconNamespace } from 'components/Icons/types';
import { useAccountSubmenuContext } from 'components/Intaserve/intaserveSidebar/components/accountSubmenu/context/useAccountSubmenuContext';
import { useConditionalFontSize } from 'components/Intaserve/intaserveSidebar/hooks/useConditionalSize';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import type { ColorTokens, ExcludePrimitiveToken } from 'config/tokens/base';
import type { NXUtils } from 'utilities/NXUtils';
import './_accountSubmenu.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type AccountSubmenuLogout = React.FC<AccountSubmenuLogoutProps>;
type AccountSubmenuLogoutProps = {
    Icon: IconifyIconNamespace.Component;
    label: string;
    color?: ExcludePrimitiveToken<ColorTokens> | NXUtils.Falsy;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const AccountSubmenuLogout: AccountSubmenuLogout = ({ Icon, label }) => {
    /***** HOOKS *****/
    const { folderLinkSize, folderIconSize } = useConditionalFontSize();
    const { setIsAccountMenuOpen } = useAccountSubmenuContext();
    const router = useRouter();

    const linkClassName = classNames('accountSubmenu__linkContainer', {
        accountSubmenu__logoutLinkColor: label === 'Logout',
    });

    /***** FUNCTIONS *****/
    function handleOnClick() {
        logoutApp(router);
        setIsAccountMenuOpen(false);
    }

    /***** RENDER *****/
    return (
        <button className={linkClassName} onClick={handleOnClick}>
            <Flex align="center" fullHeight gap={3}>
                <Icon size={folderIconSize} />
                <Text size--custom={folderLinkSize}>{label}</Text>
            </Flex>
        </button>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
