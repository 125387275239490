import { Link, useMatches } from '@tanstack/react-router';
import classNames from 'classnames';
import 'components/Intaserve/intaserveSidebar/components/navigation/folderLink/_folderLink.scss';
import { useNavFolderContext } from 'components/Intaserve/intaserveSidebar/contexts/folderContext/useNavFolderContext';
import { useNavContext } from 'components/Intaserve/intaserveSidebar/contexts/navContext/useNavContext';
import { useConditionalFontSize } from 'components/Intaserve/intaserveSidebar/hooks/useConditionalSize';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useEffect } from 'react';
import { isRouteActive } from 'router/utils/isRouteActive';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { FolderLinkProps } from 'components/Intaserve/intaserveSidebar/components/navigation/types';
import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type FolderLink = React.FC<FolderLinkProps>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const FolderLink: FolderLink = ({ path, title, Icon }) => {
    /***** HOOKS *****/
    const { setIsMobileSidebarOpen } = useNavContext();
    const { setLinks } = useNavFolderContext();
    const isMediaMobile = ViewportManager.useAppMediaViewport(['xs', 'sm']);
    const { folderLinkSize } = useConditionalFontSize();
    const isRouteMatch = useMatches({ select: (matches) => matches.some((match) => path === match.fullPath) });

    /***** FUNCTIONS *****/
    const addLink = (link: FolderLinkProps) => {
        setLinks((prevPaths) => new Set(prevPaths).add(link));
    };

    const removeLink = (link: FolderLinkProps) => {
        setLinks((prevlinks) => {
            const newPaths = new Set(prevlinks);
            newPaths.delete(link);
            return newPaths;
        });
    };

    function handleOnClick() {
        if (isMediaMobile) setIsMobileSidebarOpen(false);
    }

    /***** EFFECTS *****/
    useEffect(() => {
        const link = { path, title, Icon };
        addLink(link);

        return () => {
            removeLink(link);
        };
    }, [path]);

    /***** CONDITIONAL CLASSNAMES *****/
    const curvedLineClassnames = classNames('folderLink__linkCurvedLine', {
        'folderLink__linkCurvedLine--isActive': isRouteMatch,
    });

    /***** RENDER *****/
    if (!isRouteActive({ path })) return null;
    return (
        <Link to={path} onClick={handleOnClick} className="folderLink" activeProps={{ className: 'folderLink--isActive' }}>
            <Padding y={3}>
                <div className={curvedLineClassnames} />
                <Flex align="center">
                    <Text size--custom={folderLinkSize} bold className="folderLink__textColorTransition">
                        {title}
                    </Text>
                    {isRouteMatch && <div className="folderLink__activeLinkDot" />}
                </Flex>
            </Padding>
        </Link>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
