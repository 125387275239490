import { Link } from '@tanstack/react-router';
import type { IconifyIconNamespace } from 'components/Icons/types';
import { useAccountSubmenuContext } from 'components/Intaserve/intaserveSidebar/components/accountSubmenu/context/useAccountSubmenuContext';
import { useConditionalFontSize } from 'components/Intaserve/intaserveSidebar/hooks/useConditionalSize';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import './_accountSubmenu.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type AccountSubmenuLink = React.FC<AccountSubmenuLinkProps>;
type AccountSubmenuLinkProps = {
    link: string;
    Icon: IconifyIconNamespace.Component;
    label: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const AccountSubmenuLink: AccountSubmenuLink = ({ link, Icon, label }) => {
    /***** HOOKS *****/
    const { folderLinkSize, folderIconSize } = useConditionalFontSize();
    const { setIsAccountMenuOpen } = useAccountSubmenuContext();

    /***** RENDER *****/
    return (
        <Link to={link} className="accountSubmenu__linkContainer" onClick={() => setIsAccountMenuOpen(false)}>
            <Flex align="center" fullHeight gap={3}>
                <Icon size={folderIconSize} />
                <Text size--custom={folderLinkSize}>{label}</Text>
            </Flex>
        </Link>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
