import { AccSubmenuContext } from 'components/Intaserve/intaserveSidebar/components/accountSubmenu/context/accountSubmenuContext';
import { useContext } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useAccountSubmenuContext = () => {
    const context = useContext(AccSubmenuContext);
    if (!context) {
        throw new Error('useAccountSubmenuContext must be used within a AccountProvider');
    }

    /***** HOOK RESULTS *****/
    return context;
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
