import { Link } from '@tanstack/react-router';
import logo from 'assets/images/intaserve-header-logo-white-text.png';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import 'components/Intaserve/intaserveSidebar/_sidebar.scss';
import { useNavContext } from 'components/Intaserve/intaserveSidebar/contexts/navContext/useNavContext';
import { Flex } from 'components/Utils/Flex';
import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Header = () => {
    /***** HOOKS *****/
    const isMediaMobile = ViewportManager.useAppMediaViewport(['sm', 'xs']);
    const { setIsMobileSidebarOpen } = useNavContext();

    /***** RENDER *****/
    return (
        <Flex gap={0} className="intaserveSidebar__header">
            {isMediaMobile && (
                <button onClick={() => setIsMobileSidebarOpen(false)} className="intaserveSidebar__revealerCloseButton">
                    <PhosphorIcons.X.Bold size={25} />
                </button>
            )}
            <Link to="/" className="intaserveSidebar__logoContainer">
                <img src={logo} alt="Intaserve logo" />
            </Link>
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
