import React from 'react';
import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';
import './_ViewportContainer.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ViewportContainer = React.FC<{
    children: React.ReactNode;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
// prettier-ignore
const _ViewportContainer: ViewportContainer = ({ children }) => {
    const registerElementAsContainer = ViewportManager.useRegisterElementAsContainer("container");

    /***** RENDER *****/
    return (
        <div ref={registerElementAsContainer} className="ViewportContainer__layout">
            {children}
        </div>
    );
};

export const ViewportContainer = React.memo(_ViewportContainer);
