import logo from 'assets/images/intaserve-header-logo-small.svg';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import './_mobileHeader.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type MobileHeader = React.FC<MobileHeaderProps>;
type MobileHeaderProps = {
    setIsMobileSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const MobileHeader: MobileHeader = ({ setIsMobileSidebarOpen }) => {
    /***** RENDER *****/
    return (
        <Flex align="center" className="mobileHeader">
            <button onClick={() => setIsMobileSidebarOpen(true)}>
                <Padding xy={4}>
                    <PhosphorIcons.List.Bold white size={35} />
                </Padding>
            </button>
            <img src={logo} alt="Intaserve logo" className="mobileHeader__logo" />
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
