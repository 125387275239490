import { createContext } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export type NavigationContext = {
    openFolder: string;
    setOpenFolder: React.Dispatch<React.SetStateAction<string>>;
    setIsMobileSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NavContext = createContext<NavigationContext | undefined>(undefined);
