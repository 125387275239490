import classNames from 'classnames';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { AccountNavigationButton } from 'components/Intaserve/intaserveSidebar/components/accountNavigationButton';
import { IntaserveSidebar } from 'components/Intaserve/intaserveSidebar/components/navigation';
import { Header } from 'components/Intaserve/intaserveSidebar/components/navigation/header';
import { NavContext } from 'components/Intaserve/intaserveSidebar/contexts/navContext/navigationContext';
import { Flex } from 'components/Utils/Flex';
import { useState } from 'react';
import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';
import './_sidebar.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type IntaserveSideNavbar = React.FC<IntaserveSideNavbarProps>;
type IntaserveSideNavbarProps = {
    isMobileSidebarOpen: boolean;
    setIsMobileSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const IntaserveSideNavbar: IntaserveSideNavbar = ({ isMobileSidebarOpen, setIsMobileSidebarOpen }) => {
    /***** STATE *****/
    const [openFolder, setOpenFolder] = useState('');

    /***** HOOKS *****/
    const isHidden = !isMobileSidebarOpen;
    const isMediaMobile = ViewportManager.useAppMediaViewport(['xs', 'sm']);

    // On mobile add the grey full width background with hide functionality to the navbar wrapper
    const sidebarMobileWrapperClassNames = classNames('intaserveSidebar', {
        'intaserveSidebar__mobileContainer': isMediaMobile,
        'intaserveSidebar__mobileContainer--isHidden': isHidden
    });

    /***** RENDER *****/
    return (
        <NavContext.Provider value={{ openFolder, setOpenFolder, setIsMobileSidebarOpen }}>
            <div className={sidebarMobileWrapperClassNames}>
                <Flex direction="column" gap={0} fullHeight>
                    <Header />
                    {/* Folders and Links */}
                    <Flex direction="column" justify="between" className="intaserveSidebar__navigationContainer">
                        <Flex direction="column">
                            {/* DASHBOARD */}
                            <IntaserveSidebar.Navigation.Link title="Dashboard" Icon={PhosphorIcons.House} path="/dashboard" />

                            {/* PORTFOLIO */}
                            <IntaserveSidebar.Navigation.Folder title="Portfolio" Icon={PhosphorIcons.Folders}>
                                <IntaserveSidebar.Navigation.Folder.Link title="Domains" path="/my-services/domains" Icon={PhosphorIcons.Sun} />
                                <IntaserveSidebar.Navigation.Folder.Link title="Hosting" path="/my-services/hosting" Icon={PhosphorIcons.Sun} />
                            </IntaserveSidebar.Navigation.Folder>

                            {/* SECURITY */}
                            <IntaserveSidebar.Navigation.Link title="Security" Icon={PhosphorIcons.Lock} path="/account/security" />
                        </Flex>

                        {/* SETTINGS */}
                        <IntaserveSidebar.Navigation.Link title="Settings" Icon={PhosphorIcons.GearFine} path="/contact-support" />
                    </Flex>

                    {/* Account Links and preferences */}
                    <div className="intaserveSidebar__accountContainer">
                        <AccountNavigationButton />
                    </div>
                </Flex>
            </div>
        </NavContext.Provider>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
